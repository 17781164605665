import React from 'react';
import Vimeo from '@u-wave/react-vimeo';

const Video = ({video, id}) => (
  <Vimeo id={id} video={video} className="video"/>
);

export default () => (
  <div id="videos">
    <Video video="373912700" id="real2019"/>
    <Video video="374183090" id="3d2019"/>
  </div>
);

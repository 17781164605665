import React, { useState } from 'react';
import Contact from './contact';
import './navBar.scss';
import { withRouter } from 'react-router-dom';
import { Turn as Hamburger } from 'hamburger-react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

export default function NavBar({ history }) {
  const [showContact, setshowContact] = useState(false);
  const [showNav, setshowNav] = useState(false);
  //const [acolor, setacolor] = useState('#fff');

  /* history.listen((location, action) => {
    // location is an object like window.location
    console.log(action, location.pathname, location.state);
  });*/

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 800px)',
  });
  function menuClass() {
    if (isDesktopOrLaptop) {
      return 'menuDesktop';
    } else if (showNav) {
      return 'menu fadein';
    } else {
      return 'menu fadeout';
    }
  }

  const menu = (
    <ul
      className={menuClass()}
      id='menu'
      onClick={() => {
        setshowNav(false);
      }}
    >
      <li>
        <Link
          to='/'
          id='navpresentacion'
          onClick={() => {
            setshowNav(false);
            // setacolor('#fff');
          }}
        >
          PRESENTACIÓN
        </Link>
      </li>
      <li>
        <Link
          className='color'
          to='/reels'
          id='navreels'
          onClick={() => {
            setshowNav(false);
            //setacolor('#0094ff');
          }}
        >
          REELS
        </Link>
      </li>
      <li>
        <a
          className='color'
          id='navcontacto'
          onClick={() => {
            setshowContact(!showContact);
            setshowNav(false);
          }}
        >
          CONTACTO
        </a>
      </li>
    </ul>
  );

  return (
    <div>
      <nav className='navbar'>
        <div className='navbrand'>
          <h1>
            <a className='color' href='./'>
              K3D
            </a>
          </h1>
        </div>
        <div
          role='button'
          className='burger color'
          style={isDesktopOrLaptop ? { display: 'none' } : { display: 'block' }}
        >
          <Hamburger
            onClick={() => {
              // setacolor('#fff');
              console.log('e');
            }}
            size={30}
            toggled={showNav}
            toggle={setshowNav}
            color={'#0094ff'}
          />
        </div>
        {isDesktopOrLaptop ? menu : null}
      </nav>
      <div>{isDesktopOrLaptop ? null : menu}</div>
      <div>
        <div
          className={'backdrop ' + (showContact ? 'fadein' : 'fadeout')}
          onClick={() => setshowContact(!showContact)}
        />
        <Contact show={showContact} />
      </div>
    </div>
  );
}

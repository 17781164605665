import React, { useState, useEffect, useRef } from 'react';
import * as THREE from 'three';
import { useFrame, useLoader } from 'react-three-fiber';
import text0 from './text0.glb';
import text0info from './text0INFO.glb';
import text1 from './text1.glb';
import text1info from './text1INFO.glb';
import text2info from './text2INFO.glb';
import text3info from './text3INFO.glb';
import text2 from './text2.glb';
import text3 from './text3.glb';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useGLTF, useAnimations } from '@react-three/drei';
import { forEach } from 'lodash';

function Text({ start, model, starton }) {
  let mixer = null;
  let ison = false;
  const { scene, animations } = useLoader(GLTFLoader, model);
  mixer = new THREE.AnimationMixer(scene);
  let action = mixer.clipAction(animations[0]);
  action.clampWhenFinished = true;
  action.loop = THREE.LoopOnce;

  useFrame((state, delta) => {
    // console.log(start.to);
    if (start.to > starton) {
      mixer.update(delta);
      if (!ison) {
        console.log(model);
        ison = true;
        action.play();
      }
    }
  });

  return (
    <primitive object={scene} castShadow recibeShadows position={[0, 0, 0]} />
  );
}

function Info({ model, url }) {
  let inforef = useRef();
  const { scene } = useLoader(GLTFLoader, model);
  const [hovered, setHover] = useState(false);
  let a = 0;

  useFrame(({ clock }) => {
    if (hovered && a < 0.04) {
      let time = clock.getElapsedTime() * 7;
      inforef.current.position.y =
        inforef.current.position.y + Math.sin(time) * (0.04 - a);

      a += 0.0001;
    } else if (!hovered) {
      if (a >= 0.04) {
        //a = 0;
      }
      if (inforef.current.position.y < 0) {
        inforef.current.position.y = inforef.current.position.y + a;
      } else if (inforef.current.position.y > 0) {
        inforef.current.position.y = inforef.current.position.y - a;
      }
      a = 0.004;
    }
  });

  return (
    <primitive
      ref={inforef}
      onPointerOver={(e) => setHover(true)}
      onPointerOut={(e) => setHover(false)}
      object={scene}
      onClick={() => {
        var x = document.getElementsByClassName('color');
        console.log(x);
        for (let i = 0; i < x.length; i++) {
          console.log(x[i]);
          x[i].style.color = '#0094ff';
        }
        window.location.href = url;
      }}
      castShadow
      recibeShadows
    />
  );
}

export default function Scene4({ start, ...props }) {
  return (
    <group scale={[10, 10, 10]} {...props}>
      <Text start={start} model={text0} starton={9800} />
      <Info model={text0info} url={'/reels'} />
      <Text start={start} model={text1} starton={14000} />
      <Info model={text1info} url={'/reels'} />
      <Text start={start} model={text2} starton={18500} />
      <Text start={start} model={text3} starton={24000} />
      <Info model={text2info} url={'/reels'} />
      <Info model={text3info} url={'/'} />
    </group>
  );
}

import * as THREE from 'three';
import React, {
  Suspense,
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import { Canvas, useFrame, useThree } from 'react-three-fiber';
import { a } from '@react-spring/three';
import { a as aDom } from '@react-spring/web';
import Scene1 from './scene1/scene1';
import Scene2 from './scene2/scene2';
import Scene3 from './scene3/scene3';
import Scene4 from './scene4/scene4';
import Plane from './plane';
import useYScroll from './helpers/useYScroll';
import useXScroll from './helpers/useXScroll';
import './home.scss';
import { Html, Loader, softShadows } from '@react-three/drei';
softShadows();
const Lights = ({ mouse }) => {
  const light = useRef();
  const { size, viewport } = useThree();
  const aspect = size.width / viewport.width;
  useFrame((state) => {
    light.current.position.set(
      mouse.current[0] / aspect,
      -mouse.current[1] / aspect,
      1
    );
  });
  return (
    <>
      <pointLight
        penumbra={2}
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
        castShadow
        ref={light}
        distance={400}
        intensity={1}
        color='#f3f3f3'
      ></pointLight>
    </>
  );
};

function Camera(props) {
  const [x] = useXScroll([-400, 400], { domTarget: window });
  const ref = useRef();
  const { setDefaultCamera } = useThree();
  useEffect(() => void setDefaultCamera(ref.current), []);
  useFrame(() => ref.current.updateMatrixWorld());
  return (
    <a.perspectiveCamera
      ref={ref}
      {...props}
      rotation-y={x.to((x) => -x / 800)}
      position-x={x.to((x) => -x / 30)}
    />
  );
}

function Background({ color }) {
  const { viewport } = useThree();
  return (
    <mesh scale={[viewport.width * 11, viewport.height * 5, 1]}>
      <planeGeometry attach='geometry' args={[1, 1]} />
      <a.meshBasicMaterial attach='material' color={color} depthTest={false} />
    </mesh>
  );
}

export default function Home() {
  const [hovered, hover] = useState(false);
  const [showEnd, setshowEnd] = useState(false);
  const inicio = -200;
  const final = 60000;
  const mouse = useRef([400, -400]);
  const onMouseMove = useCallback(
    ({ clientX: x, clientY: y }) =>
      (mouse.current = [x - window.innerWidth / 2, y - window.innerHeight / 2]),
    []
  );
  const [y] = useYScroll([inicio, final], {
    domTarget: window,
  });
  // const color = y.interpolate(
  //   [inicio, 11000, final],
  //   ['#0094ff', '#f3f3f3', '#f3f3f3']
  // );
  const color = '#69c0ff';
  useEffect(() => {
    document.body.style.cursor = hovered
      ? 'pointer'
      : "url('https://raw.githubusercontent.com/chenglou/react-motion/master/demos/demo8-draggable-list/cursor.png') 39 39, auto";
  }, [hovered]);
  return (
    <>
      <Canvas
        onMouseMove={onMouseMove}
        colorManagement
        concurrent
        shadowMap
        gl={{ alpha: true }}
        onCreated={({ gl, camera }) => {
          camera.lookAt(0, 0, 0);
          gl.setClearColor(0.0, 0.0, 0.0, 0.0);
        }}
      >
        <Background color={color} />
        <Camera position={[0, 5, 25]} fov={65} near={1} far={700} />
        <ambientLight intensity={0.35} />
        <pointLight
          penumbra={2}
          shadow-mapSize-width={2048}
          shadow-mapSize-height={2048}
          castShadow
          angle={Math.PI / 2}
          intensity={0.4}
          position={[10, 15, 20]}
        />
        <fog attach='fog' args={['#91c8e1', 400, 800]} />
        <Lights mouse={mouse} />

        <Suspense
          fallback={
            <Html center>
              <Loader />
            </Html>
          }
        >
          <Plane y={y.animation} />
          <a.group position-z={y.to((y) => (y / 500) * 25)}>
            <Scene1 position={[0, 15, -520]} />
            <Scene2 position={[0, -30, -1430]} />
            {/* <Scene3 start={y.animation} position={[0, -30, -1430]} /> */}
            <Scene4 start={y.animation} position={[0, -30, -1430]} />
          </a.group>
        </Suspense>
      </Canvas>
      <aDom.div
        className='bar'
        style={{ height: y.interpolate([inicio, final], ['0%', '100%']) }}
      />
    </>
  );
}

import { useCallback, useEffect } from 'react';
import { useSpring, config } from '@react-spring/core';
import { useGesture } from 'react-use-gesture';
import clamp from 'lodash/clamp';

export default function useYScroll(bounds, props) {
  const [{ y }, set] = useSpring(() => ({ y: 0, config: config.slow }));
  const fn = useCallback(
    ({ xy: [, cy], previous: [, py], memo = y.get(), dragging }) => {
      const delta = 3.8 * (cy - py);
      const newY = clamp(dragging ? memo + delta : memo - delta, ...bounds);
      set({ y: newY });
      return newY;
    },
    [bounds, y, set]
  );
  const fnd = useCallback(
    ({ xy: [, cy], previous: [, py], memo = y.get(), dragging }) => {
      const delta = 9 * (cy - py);
      const newY = clamp(dragging ? memo + delta : memo - delta, ...bounds);
      set({ y: newY });
      return newY;
    },
    [bounds, y, set]
  );
  const bind = useGesture({ onWheel: fn, onDrag: fnd }, props);
  useEffect(() => props && props.domTarget && bind(), [props, bind]);
  return [y, bind];
}

import React from 'react';
import * as THREE from 'three';
import { useFrame, useLoader } from 'react-three-fiber';
import aereo from './aereo.glb';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

const Aereopuerto = () => {
  const { scene, animations } = useLoader(GLTFLoader, aereo);
  let mixer = null;
  mixer = new THREE.AnimationMixer(scene);
  let action = mixer.clipAction(animations[0]);

  useFrame((state, delta) => {
    mixer.update(delta);
    action.play();
  });

  return (
    <primitive object={scene} castShadow recibeShadows position={[0, 0, 0]} />
  );
};

export default function Scene2({ ...props }) {
  return (
    <group scale={[10, 10, 10]} {...props}>
      <Aereopuerto />
    </group>
  );
}

import './styles.scss';
import React, { useState } from 'react';
import Reels from './components/Reels/reels';
import Home from './components/Home/home';
import NavBar from './components/Navbar/navBar';

import { BrowserRouter as Router, Route } from 'react-router-dom';

const routes = [
  { path: '/', name: 'Home', Component: Home },
  { path: '/reels', name: 'Reels', Component: Reels },
];

function App() {
  return (
    <div>
      <Router>
        <div>
          <NavBar />
          {routes.map(({ path, name, Component }) => (
            <Route key={path} exact path={path}>
              <div className={name === 'Home' ? 'page homepage' : 'page'}>
                <Component />
              </div>
            </Route>
          ))}
        </div>
      </Router>
    </div>
  );
}

export default App;

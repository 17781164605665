import React, { useMemo } from 'react';
import * as THREE from 'three';
import { useFrame, useLoader } from 'react-three-fiber';
import sun from './sun.glb';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { softShadows } from '@react-three/drei';
softShadows();
function TheModel({ start }) {
  let starton = 3200;
  let mixer = null;
  let ison = false;
  const m = useLoader(GLTFLoader, sun);
  mixer = new THREE.AnimationMixer(m.scene);
  var action = mixer.clipAction(m.animations[0]);
  action.clampWhenFinished = true;
  action.loop = THREE.LoopOnce;

  m.scene.children.forEach((mesh, i) => {
    mesh.castShadow = true;
  });
  m.castShadow = true;
  m.scene.castShadow = true;
  const geo = useMemo(() => m.scene.geometry, []);
  const mat = useMemo(() => m.material, []);

  useFrame((state, delta) => {
    if (start.to > starton) {
      mixer.update(delta);
      if (!ison) {
        ison = true;
        action.play();
      }
    }
  });

  return (
    <mesh castShadow>
      <primitive attach='geometry' object={m.scene} />
      <meshStandardMaterial attach='material' {...mat} />
    </mesh>
  );
}

export default function Scene3({ start, ...props }) {
  return (
    <group scale={[10, 10, 10]} {...props}>
      <TheModel start={start} />
    </group>
  );
}

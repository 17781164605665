import React, { useRef } from 'react';
import * as THREE from 'three';
import { useFrame, useLoader } from 'react-three-fiber';
import plane from './plane.glb';
import aspas from './aspas.glb';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

function Avion() {
  let mixer = null;

  let ison = false;
  const { scene, animations } = useLoader(GLTFLoader, plane);
  mixer = new THREE.AnimationMixer(scene);
  var action = mixer.clipAction(animations[0]);
  action.clampWhenFinished = true;
  action.loop = THREE.LoopOnce;
  action.play();

  useFrame((state, delta) => {
    mixer.update(delta);
    if (!ison) {
      ison = true;
      action.play();
    }
  });

  return (
    <primitive object={scene} castShadow recibeShadows position={[0, 0, 0]} />
  );
}
function Aspas({ y }) {
  let mixer = null;
  let rot = 0;
  let planeRef = useRef();
  //let random = Math.random() * 5;

  let ison = false;
  const { scene, animations } = useLoader(GLTFLoader, aspas);
  mixer = new THREE.AnimationMixer(scene);
  var action = mixer.clipAction(animations[0]);
  action.clampWhenFinished = true;
  action.loop = THREE.LoopOnce;
  action.play();

  useFrame((state, delta) => {
    mixer.update(delta);
    if (!ison) {
      ison = true;
      action.play();
    }
  });

  useFrame(({ clock }) => {
    let time = clock.getElapsedTime() + y.to / 2000;
    //console.log(time * 5);
    //console.log(y.to / 10000);
    planeRef.current.children[0].children[0].rotation.z = time * 15;
  });

  return (
    <primitive
      ref={planeRef}
      object={scene}
      castShadow
      recibeShadows
      position={[0, 0, 0]}
    />
  );
}

export default function Plane({ y, ...props }) {
  let planeRef = useRef();
  useFrame(({ clock }) => {
    let time = clock.getElapsedTime() + y.to / 1000;
    planeRef.current.position.y = Math.sin(time) * 0.9;
  });

  return (
    <group ref={planeRef} scale={[1, 1, 1]} {...props}>
      <Avion />
      <Aspas y={y} />
    </group>
  );
}

import { useCallback, useEffect } from 'react';
import { useSpring, config } from '@react-spring/core';
import { useGesture } from 'react-use-gesture';
import clamp from 'lodash/clamp';

export default function useXScroll(bounds, props) {
  const [{ x }, set] = useSpring(() => ({ x: 0, config: config.molasses }));
  const fn = useCallback(
    ({ xy: [cx], previous: [px], memo = x.get(), dragging }) => {
      const delta = cx - px;
      const newX = clamp(dragging ? memo + delta : memo - delta, ...bounds);
      set({ x: newX });
      return newX;
    },
    [bounds, x, set]
  );
  const bind = useGesture({ onWheel: fn, onDrag: fn }, props);
  useEffect(() => props && props.domTarget && bind(), [props, bind]);
  return [x, bind];
}

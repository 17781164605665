import cloud from './cloud10.png';
import * as THREE from 'three';
import React, { useRef } from 'react';
import { useFrame, useLoader } from 'react-three-fiber';

function Cloud({ position, scale }) {
  let planeRef = useRef();
  let texture = useLoader(THREE.TextureLoader, cloud);
  let random = Math.random() * 9;
  useFrame(({ clock }) => {
    let time = clock.getElapsedTime() + random;
    planeRef.current.position.y =
      planeRef.current.position.y + Math.sin(time) * 0.04;
  });

  return (
    <mesh rotation={[0, 0, 0]} ref={planeRef} position={position}>
      <planeBufferGeometry
        attach='geometry'
        args={[scale * 1.3, scale]}
      ></planeBufferGeometry>
      <meshBasicMaterial
        attach='material'
        map={texture}
        transparent={true}
      ></meshBasicMaterial>
    </mesh>
  );
}

function Clouds() {
  let clouds = [];
  //let count = 10;
  let s = 15;
  for (let i = 6; i < 14; i++) {
    let r = Math.random();
    let d = i * 240;
    clouds.push(
      <group key={i} scale={[3.5, 3, 1]} position={[6 - r * 20, 5, -d]}>
        <Cloud scale={1.5 * s} position={[3.1 * s, -1, 200]} />
        <Cloud scale={1.5 * s} position={[-3.1 * s, -1, 200]} />
        <Cloud scale={3 * s} position={[6.2 * s, 1, 0]} />
        <Cloud scale={3 * s} position={[-6.2 * s, 1, 0]} />
        <Cloud scale={6 * s} position={[9.5 * s, 10, 200]} />
        <Cloud scale={6 * s} position={[-9.5 * s, 10, 200]} />
      </group>
    );
  }

  for (let i = 14; i < 17; i++) {
    let r = Math.random();
    let d = i * 60;
    clouds.push(
      <group key={i} scale={[2, 1.4, 1]} position={[6 - r * 20, -66, -d]}>
        <Cloud scale={4 * s} position={[0, -10, 0]} />
        <Cloud scale={1.5 * s} position={[2.1 * s, -1, -30]} />
        <Cloud scale={1.5 * s} position={[-2.1 * s, -1, -30]} />
        <Cloud scale={2 * s} position={[4.2 * s, 1, 0]} />
        <Cloud scale={2 * s} position={[-4.2 * s, 1, 0]} />
        <Cloud scale={7 * s} position={[7.5 * s, 5, -30]} />
        <Cloud scale={7 * s} position={[-7.5 * s, 5, -30]} />
        <Cloud scale={9 * s} position={[17.5 * s, 25, -0]} />
        <Cloud scale={9 * s} position={[-17.5 * s, 25, -0]} />
      </group>
    );
  }

  return <group position={[0, 20, 900]}>{clouds}</group>;
}

export default function Scene1({ ...props }) {
  return (
    <group {...props}>
      <Clouds />
    </group>
  );
}

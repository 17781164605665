import React from "react";
import "./contact.scss";

export default (props) => (
  <div className={"modal " + (props.show ? "fadein" : "fadeout")}>
    <h1>CONTACTO</h1>
    <div>
      <img src="assets/svg/correo.svg" alt="" />
      <a href="mailto:contacto@k3d.es">contacto@k3d.es</a>
    </div>
    <div>
      <img src="assets/svg/llamada-telefonica.svg" alt="" />
      <a href="tel:916691946">916691946</a>
    </div>
    <div>
      Contacta con nosotros por teléfono o correo, estás en buenas manos.
    </div>
  </div>
);
